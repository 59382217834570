<template>
  <up-card>
    <div class="photo__wrapper">
      <div class="logo-item">
        <img
          :src="photo"
          alt=""
        >
      </div>
    </div>
    <template v-slot:stretch-link>
      <router-link
        :to="{ name: 'partnerProfile', params: { id: `${this.partnerId}` } }"
        class="stretch-link"
      />
    </template>
  </up-card>
</template>

<script>
export default {
  name: 'PartnerCard',
  props: {
    photo: {
      type: String,
      default: '',
    },
    partnerId: {
      type: [String, Number],
      requared: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.photo__wrapper {
  display: grid;
  grid-template-columns: [start] 1fr [end];
  grid-template-rows: [start] 1fr [end];
  height: 100%;
  max-height: 200px;
  overflow: hidden;

  // &:after {
  //   content: '';
  //   grid-column: start / end;
  //   grid-row: start / end;
  //   display: block;
  //   padding-bottom: 50%;
  // }
  // .col-6 &:after {
  //   padding-bottom: 100%;
  // }
  .logo-item {
    grid-column: start / end;
    grid-row: start / end;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: inherit;
    margin: 0 auto;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
// .col-6 {
//   img {
//     max-height: 35px;
//   }
// }
// .col-12, .profile-card {
//   img {
//     max-height: 44px;
//   }
// }
.stretch-link {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
