<template>
  <div class="profile__wrapper">
    <profile-top
      :photo="photo"
      :name="name"
      :position="position"
      :company="company"
      :activityTag="activityTag"
    />
    <div class="profile-system__wrapper">
      <div class="profile-content full-size">
        <div
          v-if="isHotLink"
          class="hot-link-panel"
        >
            <div class="row justify-content-evenly">
              <div
                v-if="isMessenger"
                :class="cssClassHotLink"
              >
                <up-card class="">
                  <button
                    type="button"
                    class="btn btn-messenger"
                    @click="getChatUuid"
                  >
                    <div class="inner-text">
                      <i class="exu-mail-2"></i> {{ $t('participantProfile.message_btn') }}
                    </div>
                  </button>
                </up-card>
              </div>
              <div
                v-if="isMM"
                :class="cssClassHotLink"
              >
                <up-card>
                  <router-link :to="{ name: 'newMatch' }">
                    Предложить встречу
                  </router-link>
                </up-card>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="profile-content__wrapper">
      <div
        v-if="description"
        class="profile-content"
      >
        <p class="description-paragraph">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import appBaseNav from '@/components/mixins/appBaseNav';
import participants from '@/components/mixins/methodsParticipants';
import methodsChat from '@/components/mixins/methodsChat';

export default {
  name: 'ParticipantProfile',
  props: {
    id: {
      requared: true,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      participant: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  mixins: [appBaseNav, participants, methodsChat],
  computed: {
    name() {
      return this.participant.name || '';
    },
    position() {
      return this.participant.position || '';
    },
    company() {
      return this.participant.company || '';
    },
    photo() {
      return this.participant.photo || '';
    },
    activityTag() {
      return this.participant.activity || '';
    },
    description() {
      return this.participant.description || '';
    },
    isMessenger() {
      return this.$store.getters.isMessenger;
    },
    isMM() {
      return this.$store.getters.isMM;
    },
    isHotLink() {
      return this.isMessenger || this.isMM;
    },
    cssClassHotLink() {
      if (!this.isMM || !this.isMessenger) return 'col-12';
      return 'col-6';
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('participantProfile.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('participantProfile.header_title'));
    this.getParticipantPersone(this.id)
      .then((result) => {
        this.participant = { ...result };
      });
  },
  methods: {
    getChatUuid() {
      this.getChatRoomByUser(this.id)
        .then((results) => {
          this.$router.push({ name: 'chatView', params: { uuid: results.uuid } });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 100%;
  padding-bottom: 24px;
}
.profile-content__wrapper {
  display: flex;
  align-items: center;
  flex: 3 1 100%;
  align-self: stretch;
}
.profile-system__wrapper {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  align-self: stretch;
}
.profile-content {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &.full-size {
    width: 100%;
  }
  .hot-link-panel {
    width: 100%;
  }

  .description-paragraph {
    color: #95969D;
    // font-family: Roboto;
    // font-size: 14px;
    text-align: justify;
    margin: 0;
  }
}
.btn-messenger {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 1rem;
  color: #356899;
  border: 2px solid #356899;
  border-radius: 16px;
  background: #fff;
  gap: .5rem;

  & .inner-text {
    display: flex;
    align-items: baseline;
    gap: .5rem;
  }
}
</style>
