<template>
  <div class="match-form__wrapper">
    <div class="match-form__time-options">
      <nav-btn-tab
        v-if="optionsDays.length"
        :idNavTab="'nav-tab-days'"
        :tabs="optionsDays"
        :cur-val="selectedDay"
        @change-value="updSelectedDay"
      />
      <nav-btn-tab
        v-if="optionsTime.length"
        :idNavTab="'nav-tab-times'"
        :tabs="optionsTime"
        :cur-val="selectedTime"
        @change-value="updSelectedTime"
      />
    </div>
    <h1>New match</h1>
  </div>
</template>
<script>
export default {
  new: 'EditMatchView',
  props: {
    locale: {
      type: String,
    },
    participantID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedDay: null,
      selectedTime: null,
    };
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('programList.header_title'));
    },
  },
  computed: {
    optionsDays() {
      return [
        { name: '16 декабря', value: '20241216' },
        { name: '17 декабря', value: '20241217' },
        { name: '18 декабря', value: '20241218' },
        { name: '19 декабря', value: '20241219' },
      ];
    },
    optionsTime() {
      const hours = ['9', '10', '11', '12', '13', '14', '15', '16', '17', '18'];
      const minuts = ['00', '15', '30', '45'];

      const timeline = [];
      hours.forEach((hour) => {
        minuts.forEach((minut) => {
          timeline.push({ name: `${hour}:${minut}`, value: `${hour}:${minut}` });
        });
      });
      return timeline;
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('programList.header_title'));
  },
  methods: {
    updSelectedDay(day) {
      this.selectedDay = day;
    },
    updSelectedTime(time) {
      this.selectedTime = time;
    },
  },
};
</script>
<style lang="scss">
.match-form {
  &__wrapper {
    padding: 12px;
  }
  &__time-options {

  }
}
</style>
