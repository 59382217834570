<template>
  <div class="bottom-nav__wrapper">
    <nav class="bottom-nav">
      <router-link :to="{ name: 'program' }">
        <i class="exu-daily-book" />
      </router-link>
      <router-link to="/participants/1">
        <i class="exu-users" />
      </router-link>
      <router-link to="/chat">
        <span
          v-if="newChatUnread"
          class="bubble"
        >
          {{ newChatUnread }}
        </span>
        <i class="exu-mail-2" />
      </router-link>
      <router-link to="/notifications/1">
        <i class="exu-annotation-alert" />
        <span
          v-if="newNotification"
          class="bubble"
        >
          {{ newNotification }}
        </span>
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'BottomNav',
  computed: {
    newNotification() {
      return this.$store.getters.getNewNotificationCount < 10 ? this.$store.getters.getNewNotificationCount : '9+';
    },
    newChatUnread() {
      return this.$store.getters.getUnreadChatCount < 100 ? this.$store.getters.getUnreadChatCount : '9+';
    },
  },
};
</script>

<style lang="scss">
.bottom-nav {
  display: flex;
  width: 100%;
  max-width: 760px;
  // height: 60px;
  padding: 10px 10px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  background: #fefefe;
  // border-top: 2px solid #F6F6FA;;
  border-radius: 30px;
  box-shadow: 0 0 1px 1px rgba(175, 176, 182, .25);

  @media (min-width: 480px) {
    & {
      width: auto;
      gap: 40px;
    }
  }

  & > a, .menu-point {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 2px solid #356899;
    border-radius: 50%;
    text-decoration: none;
    color: #356899;
    & > [class^="exu-"], & > [class*=" exu-"] {
      font-size: 20px;
    }

    &.router-link-exact-active, &.router-link-active {
      background: #356899;
      & > [class^="exu-"], & > [class*=" exu-"] {
        color: #F0F0F0;
      }
    }

    .bubble {
      position: absolute;
      bottom: -2px;
      right: -2px;
      display: flex;
      width: 20px;
      height: 20px;
      padding: 3px;
      font-size: .8rem;
      line-height: .8rem;
      background: #FC1010;
      border-radius: 50%;
      color: #FEFEFE;
      align-items: center;
      justify-content: center;
    }
  }
  .menu-point {
    color: #95969D;
    border-color: #95969D;
  }
}
</style>
