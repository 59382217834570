<template>
  <div
    id="chatScroll"
    class="chat__wrapper"
  >
    <div class="message-list">
      <div
        v-for="message in messages"
        :key="message.id"
        :class="message.styleClass"
        class="message__wrapper"
      >
        <div class="message-item">
          {{ message.text }}
          <div class="status-message">{{ message.time }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="chat__input-row">
    <input
      v-model="inputMessage"
      @keyup.enter="sendingMessage"
    >
    <button
      type="button"
      @click="sendingMessage"
    >
      <i class="exu-send"></i>
    </button>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import methodsChat from '@/components/mixins/methodsChat';
import participants from '@/components/mixins/methodsParticipants';

export default {
  name: 'chatRoom',
  props: {
    uuid: {
      required: true,
    },
  },
  data() {
    return {
      chatWrapperElement: null,
      invitedID: null,
      messages: {
        type: Array,
        default: () => ([]),
      },
      seporator_id: null,
      lastMessageDay: null,
      lastMessageID: 0,
      inputMessage: '',
    };
  },
  mixins: [methodsChat, participants],
  mounted() {
    this.chatWrapperElement = document.getElementById('chatScroll');
    this.getChatRoom(this.uuid)
      .then(async (data) => {
        this.invitedID = data.results.invited[0].id;
        this.messages = this.processingMessageList(data.results.messages);
        this.$store.commit('setPageTitle', data.results.invited[0].name);
        this.$store.commit('setUnreadChatCount', 0);
      })
      .then(() => {
        this.chatWrapperElement.scrollTop = this.chatWrapperElement.scrollHeight;
        this.$store.commit('setUpdatingID', this.startPingChat());
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    processingMessageList(messages) {
      const preMessagesList = [];
      let isSeparator = false;
      messages.forEach((message) => {
        const currentDay = DateTime.fromMillis(message.sent_ts);
        if (this.lastMessageDay !== currentDay.get('day')) {
          this.lastMessageDay = currentDay.get('day');
          preMessagesList.push({
            id: message.sent_ts,
            text: this.$store.getters.getLang === 'ru'
              ? currentDay.setLocale('ru').toFormat('d MMMM yyyy')
              : currentDay.setLocale('en').toFormat('MMMM d, yyyy'),
            styleClass: 'tech-message',
          });
        }
        const {
          // eslint-disable-next-line camelcase
          id, text, author, is_unread,
        } = message;
        // eslint-disable-next-line camelcase
        if (is_unread && !isSeparator) {
          if (this.seporator_id && this.messages.length > 0) {
            this.messages.splice(this.seporator_id, 1);
          }
          isSeparator = true;
          this.seporator_id = preMessagesList.push({
            id: 'separator',
            text: 'Новые сообщения',
            styleClass: 'tech-message',
          }) + (this.messages.length || 0) - 1;
        }
        preMessagesList.push({
          id,
          text,
          author,
          styleClass: message.author === this.invitedID ? 'invided-message' : 'main-message',
          time: currentDay.toFormat('HH:mm'),
        });
      });
      this.lastMessageID = messages.length
        ? messages[messages.length - 1]?.message_id : this.lastMessageID;
      return preMessagesList;
    },
    gettingNewMessage() {
      this.getNewMessage(this.uuid, this.lastMessageID)
        .then((data) => {
          this.processingMessageList(data.results.messages)
            .forEach((message) => {
              this.messages.push(message);
            });
          return data.results.messages.length;
        })
        .then((messagesLength) => {
          if (messagesLength > 0) {
            this.chatWrapperElement.scrollTop = this.chatWrapperElement.scrollHeight;
          }
        })
        .catch(() => {});
    },
    sendingMessage() {
      this.sendMessage({
        uuid: this.uuid,
        participant: this.invitedID,
        text: this.inputMessage,
      })
        .then(() => {
          this.inputMessage = '';
          this.gettingNewMessage();
        })
        .catch(() => {
        });
    },
    pingChat() {
      this.endPingChat();
      this.gettingNewMessage();
      this.$store.commit('setUpdatingID', this.startPingChat());
    },
    startPingChat() {
      return setTimeout(this.pingChat, 4000);
    },
    endPingChat() {
      clearTimeout(this.$store.getters.getUpdatingID);
      this.$store.commit('setUpdatingID', null);
    },
  },
  beforeUnmount() {
    this.endPingChat();
  },
};
</script>
<style lang="scss" scoped>
  .chat {
    &__wrapper {
      grid-area: chat;
      overflow-x: hidden;
      overflow-y: auto;
    }
    &__input-row {
      grid-area: inputField;
      display: flex;

      input {
        height: 36px;
        flex: 1 1 100%;
        background: #FFFFFF;
        border: 1px solid #e0e0e0;
        border-radius: 12px 0 0 12px;
        padding: 5px 15px;
      }
      button {
        height: 36px;
        flex: 1 1 auto;
        background: #FFFFFF;
        border: 1px solid #e0e0e0;
        border-left: none;
        border-radius: 0 12px 12px 0;
        padding: 5px 15px;
        color: #808080;
      }
    }
  }
  .message-list {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .message {
      &__wrapper {
        display: flex;
        width: 100%;
        justify-content: center;

        &.invided-message {
          justify-content: start;
          & > div {
            background-color: #D0E8F8;
            border: 1px solid #a5bdb2;
            border-bottom-left-radius: 0;
          }
        }
        &.main-message {
          justify-content: end;
          // & > div { background-color: #CFE0CF; }
          & > div {
            background-color: #CCFFCC;
            border: 1px solid #a5bdb2;
            border-bottom-right-radius: 0;
          }
        }
        &.tech-message {
          & > div {
            background: rgba(254,254,254,.75);
            border: 1px solid #CCCCCC;
            color: #A0A0A0;
            padding: 3px 12px;
            font-size: .8rem;
          }
        }
      }
      &-item {
        max-width: 85%;
        padding: 10px;
        border-radius: 10px;

        .status-message {
          font-size: .8rem;
        }
      }
    }
  }
</style>
