<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    v-if="itemData.type === 'date'"
    class="calendar-day__wrapper"
  >
    <div class="calendar-day">{{ itemData.text }}</div>
  </div>
  <div
    v-else
    class="calendar-item__wrapper"
  >
    <div class="timemark bookmark">
      <i class="exu-bookmark" />
    </div>
    <div class="calendar-item">
      <up-card>
        <div class="calendar-item__inner">
          <div
            @click="titleHandle"
            class="title-block"
          >
            <p
              v-if="mainTitle"
              class="main-title"
            >
              {{ mainTitle }}
            </p>
            <p
              v-if="subTitle"
              class="sub-title"
            >
              {{ subTitle }}
            </p>
          </div>
          <div class="upCard-bottom">
            <div
              @click="titleHandle"
            >
              <div class="time-place">
                <span class="calendar-item-time">
                  {{ timeStartEnd }},
                </span>
                <span class="calendar-item-place">{{ place }}</span>
              </div>
              <div class="system-comment">
                <span>{{ systemComment }}</span>
              </div>
            </div>
            <button
              class="btn bookmark"
              @click="markFavorit"
            >
              <i class="exu-bookmark-check-fill" />
            </button>
          </div>
        </div>
      </up-card>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  name: 'UserCalendarItem',
  props: {
    itemData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tsToUrlDate() {
      return DateTime.fromMillis(this.itemData.start_ts).toFormat('yyyyLLdd');
    },
    timeStart() {
      return DateTime.fromMillis(this.itemData.start_ts).setZone(this.$store.getters.timeZone);
    },
    timeEnd() {
      return this.timeStart.plus(this.itemData.duration_ts);
    },
    timeStartEnd() {
      return `${this.timeStart.toFormat('H:mm')} - ${this.timeEnd.toFormat('H:mm')}`;
    },
    place() {
      return this.itemData.place || '';
    },
    mainTitle() {
      return this.itemData.main_title;
    },
    subTitle() {
      return this.itemData.sub_title;
    },
    systemComment() {
      return 'Деловая программа';
    },
    isModerators() {
      return this.itemData.moderators.length > 0;
    },
    isSpeakers() {
      return this.itemData.speakers.length > 0 && !this.isOpenSection;
    },
    dataForMarkFavorit() {
      return {
        id: this.itemData.id,
        hashLink: this.itemData.hashLink || null,
        start_ts: this.itemData.start_ts,
        duration_ts: this.itemData.duration_ts,
        place: this.itemData.place,
        main_title: this.itemData.main_title,
        sub_title: this.itemData.sub_title,
        moderators: this.isModerators ? this.itemData.moderators : [],
        speakers: this.isSpeakers ? this.itemData.speakers : [],
        type: 'section',
      };
    },
  },
  methods: {
    markFavorit() {
      // console.log('!');
      this.$emit('mark-favorite', this.dataForMarkFavorit);
    },
    titleHandle() {
      if (this.itemData.hashLink) {
        this.$router.push({ name: 'program', params: { date: this.tsToUrlDate }, hash: `#${this.itemData.hashLink}` });
      }
    },
  },
};
</script>
