<template>
  <base-list-view
    @state-scroll="scrollList"
  >
    <div class="partner-list">
      <template
        v-for="partner in partnerList"
        :key="partner.id"
      >
        <h2 v-if="partner.type === 'title'">
          {{ partner.name }}
        </h2>
        <partner-card
          v-if="partner.type === 'partner'"
          :photo="partner.logo"
          :partner-id="partner.id"
          :class="`partner-item item-size-${partner.size}`"
        />
      </template>
      <system-card
        v-if="systemMessage"
        :system-message="systemMessage"
        :cbFunc="repeatGetPage"
      />
      <pre-loader v-if="isLoading"/>
    </div>
  </base-list-view>
</template>
<script>
import { DateTime } from 'luxon';
import appBaseNav from '@/components/mixins/appBaseNav';
import sponsors from '@/components/mixins/methodsSponsors';
import banners from '@/components/mixins/methodsBanners';

import baseListView from '@/components/shared/baseListView.vue';
import SystemCard from '@/components/shared/upCard/SystemCard.vue';

export default {
  name: 'ListPartnersView',
  components: { baseListView, SystemCard },
  props: {
    launchDownload: {
      type: Boolean,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      partnerList: [],
      categoryList: null,
      isLoading: true,
      curPage: 1,
      countPage: null,

      itPriority: null,

      isLastDownloaded: false,
      systemMessage: null,
    };
  },
  mixins: [appBaseNav, sponsors, banners],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('partnerList.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('partnerList.header_title'));
    Promise.all([
      this.getBanner('sponsor', this.$store.getters.getBannerPartnerTs),
      this.getSponsorCategory(),
    ])
      .then(([
        banner,
        catgory,
      ]) => {
        this.isLoading = false;
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerPartnerTs', nowMilis);
          this.$store.commit('setBannerPartnerTs', nowMilis);
        }

        this.categoryList = new Map([...catgory].map((item) => ([item.id, { ...item }])));
        this.getPage();
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    getPage() {
      this.isLoading = true;
      if (this.countPage === null || this.curPage <= this.countPage) {
        this.getSponsorList(this.curPage)
          .then((data) => {
            this.isLoading = false;
            this.$emit('turnOff', {
              stateDownload: false,
              stateCheckInbox: false,
            });
            if (data?.results && Array.isArray(data.results)) {
              this.partnerList = [
                ...this.partnerList,
                ...this.presetPartnerLis(data.results),
              ];
              this.countPage = data.num_pages;
              this.curPage = data.page;
              this.isLastDownloaded = true;
              this.systemMessage = null;
            } else {
              this.isLastDownloaded = false;
              this.systemMessage = this.$t('system.bad_connect');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.isLoading = false;
        this.$emit('turnOff', {
          stateCheckInbox: true,
        });
      }
    },
    presetPartnerLis(list) {
      const complitList = [];
      list.forEach((item) => {
        if (this.itPriority !== item.category_id) {
          this.itPriority = item.category_id;
          complitList.push({
            ...this.categoryList.get(item.category_id),
            type: 'title',
          });
        }
        complitList.push({
          ...item,
          size: this.categoryList.get(item.category_id).size,
          type: 'partner',
        });
      });
      return complitList;
    },
    scrollList(prop) {
      if (prop.endPage && !this.isLoading && this.isLastDownloaded) {
        this.curPage += this.isLastDownloaded ? 1 : 0;
        this.getPage();
      }
    },
  },
};
</script>
<style lang="scss">
.partner {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    h2 {
      flex: 1 0 100%;
      text-align: center;
      margin: 0;
      padding: 12px 12px 0;
    }
  }
  &-list &-item {
    height: 160px;
    padding: 12px;

    &.item-size-6 {
      flex: 1 1 40%;
      max-width: calc(50% - 6px);
    }
    &.item-size-12 {
      flex: 1 0 100%;
    }
    @media (min-width: 480px) {
      flex: 1 1 30%;
      max-width: calc(33.333% - 6px);

      &.item-size-6 {
        flex: 1 0 25%;
        max-width: calc(25% - 9px);
      }
      &.item-size-12 {
        flex: 1 0 100%;
        max-width: 100%;
        padding: 24px 48px;
        // flex: 1 0 40%;
        // max-width: calc(50% - 6px);
      }
    }

    & .upCard {
      border-radius: 16px;
    }
  }
}
</style>
