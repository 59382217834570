<template>
  <up-card class="upCard-sm">
    <div :class="notificationStatus">
      <div class="notification__inner">
        <div class="notification-public">
          {{ publicDate }}
        </div>
        <div class="d-flex flex-row">
          <div class="icon-status">
            <i
              v-if="notification.viewed"
              class="exu-annotation-check"
            />
            <i
              v-else
              class="exu-annotation-alert"
            />
          </div>
          <div class="notification-info">
            <div class="notification-title">
              {{ notification.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:stretch-link>
      <router-link
        :to="{ path: `/notification/${notification.id}` }"
        class="stretch-link"
      />
    </template>
  </up-card>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  name: 'NotificationCard',
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    notificationStatus() {
      return this.notification.viewed === null ? 'notification new' : 'notification';
    },
    publicDate() {
      if (this.notification.public_ts) {
        return DateTime
          .fromMillis(this.notification.public_ts)
          .setZone('UTC+0')
          .setLocale(this.$store.getters.getLang)
          .toFormat('d MMMM HH:mm');
      }
      return '';
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.notification {
  color: #95969D;
  &.new {
    color: #2c3e50;
  }
  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px 15px;
    .d-flex.flex-row {
      gap: 5px;
    }
  }
  &-public {
    flex: 1 1 100%;
    margin: -16px -12px 0;
    padding: 10px 10px 5px;
    font-size: .75rem;
    line-height: .75rem;
    // color: #95969D;
    background: #eaeaef;
  }
  &-info {
    flex: 1 1 auto;
  }
}
.icon-status {
  flex: 0 0 auto;
  font-size: 24px;
  line-height: 24px;
}
</style>
