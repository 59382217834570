<template>
  <div class="page__wrapper">
    <div class="container-static">
      <h1>404 page not found</h1>
    </div>
  </div>
</template>
<script>
import appBaseNav from '@/components/mixins/appBaseNav';

export default {
  name: 'PageSystem404',
  mixins: [appBaseNav],
  mounted() {
    this.$store.commit('setPageTitle', '404');
  },
  methods: {
    toEventList() {
      // console.log('click to events list');
      this.$router.push({ name: 'EventsListView' });
    },
  },
};
</script>
