<template>
  <aside>
    <grid-menu
      :locale="locale"
      :items-list="pointsList"
      :updatePwaExist="updatePwaExist"
      @switch-locale="changeLocale"
      @update-pwa="handleUpdatePwa(true)"
    />
  </aside>
</template>

<script>
import gridMenu from '@/components/shared/asideMenu/gridMenu.vue';

export default {
  components: { gridMenu },
  props: {
    pointsList: {
      type: Array,
      default: () => [],
    },
    locale: {
      type: String,
      default: 'ru',
    },
    updatePwaExist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    changeLocale() {
      this.$emit('switch-locale', this.locale === 'ru' ? 'en' : 'ru');
    },
    handleUpdatePwa() {
      this.$emit('update-pwa', !this.updatePWAExist);
    },
  },
};
</script>

<style lang="scss" scoped>
aside {
  grid-area: aside;
  display: flex;
  justify-content: end;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  transition: opacity .5s;
  z-index: 200;

  .aside__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #FFF;
    /* box-shadow: 0px -10px 10px 10px rgba(0, 0, 0, .25); */
    transition: transform .5s;
    border-left: 1px solid #e0e0e0;
  }
}
</style>
