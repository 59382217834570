<template>
  <div class="system-message__wrapper">
    <up-card class="system-message">
      <div class="system-message__inner">
        <div>
          <i class="exu-wifi-off"></i>
        </div>
        <div>
          {{ systemMessage }}
          <button
            @click="buttonHandle()"
            class="btn-repeat"
          >
            {{ btnRepeat }}
          </button>
        </div>
      </div>
    </up-card>
  </div>
</template>
<script>
export default {
  name: 'SystemCard',
  props: {
    systemMessage: {
      type: String,
      default: null,
    },
    cbFunc: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    btnRepeat() {
      return this.$t('system.btn_repeat_download');
    },
  },
  methods: {
    buttonHandle() {
      this.cbFunc();
    },
  },
};
</script>
<style lang="scss" scoped>
.system-message {
  &__wrapper {
    padding: 12px 0;
  }
  &__inner {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .btn-repeat {
    width: 100%;
    margin-top: 12px;
    background: transparent;
    border: 2px solid #356899;
    border-radius: 8px;
    color: #356899;
  }
  i {
    font-size: 2em;
  }
  padding: 12px 12px;
  border: 1px solid #AFB0B6;
  color: #AFB0B6;
}
</style>
