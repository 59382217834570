import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getSpeackersList(page = null) {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/speaker/page/${page ? `${page}/` : ''}`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => {
          this.$store.commit('setProgressVal', 100);
          return data;
        })
        .catch((error) => {
          this.$store.commit('setProgressVal', 100);
          Promise.reject(error);
        });
    },
    getSpeackerPersone(id) {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/speaker/${id}`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => data?.results || {})
        .catch((error) => {
          this.$store.commit('setProgressVal', 100);
          Promise.reject(error);
        });
    },
  },
};
