<template>
  <div class="question">
    <up-card>
      <p class="break-line">{{ text }}</p>
      <div class="under-text">
        <div class="author-link">
          <i class="exu-user" />
          <router-link
            :to="{name: 'participantProfile', params: {id: this.question.author}}"
            class="participant-link"
          >
          {{ participant }}
          </router-link>
        </div>
        <button
          class="btn-like"
          :class="{'liked': isLiked}"
          @click="likeClick"
        >
          <span v-if="likes > 0">{{ likes }}</span>
          <i class="exu-thumbs-up" />
        </button>
      </div>
    </up-card>
  </div>
</template>
<script>
import lectures from '@/components/mixins/methodsLectures';

export default {
  name: 'questionItem',
  mixins: [lectures],
  props: ['question'],
  data() {
    return {
      itemLiked: null,
      countLiked: null,
    };
  },
  computed: {
    text() {
      return this.question.text;
    },
    likes() {
      return this.countLiked;
    },
    isLiked() {
      return this.itemLiked;
    },
    participant() {
      return this.question.author_name;
    },
  },
  created() {
    this.itemLiked = this.question.liked_it;
    this.countLiked = this.question.liked_count;
  },
  methods: {
    likeClick() {
      this.itemLiked = !this.itemLiked;
      this.countLiked += this.itemLiked ? 1 : -1;
      this.doLiking({
        liked_it: !this.itemLiked,
        lecture_id: this.question.lecture,
        question_id: this.question.id,
      })
        // .then((response) => {
        //   console.log(response);
        // })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.question {
  p {
    margin: 0;
    &.break-line {
      white-space: break-spaces;
    }
  }
  .under-text {
    display: flex;
    align-items: center;

    .participant-link {
      flex: 1 1 100%;
      justify-content: start;
      color: #356899;
    }
  }
}
.author-link {
  flex: 1 1 100%;
  display: flex;
  gap: 6px;
  text-wrap: nowrap;
  align-items: baseline;
}
.btn-like {
  display: flex;
  width: 6rem;
  justify-content: center;
  align-items: baseline;
  gap: .5rem;
  font-size: 1rem;
  padding: .25rem .75rem;
  border: none;
  border-radius: 16px;
  background: #F6F6FA;
  color: #AFB0B6;

  & > i {
    margin: .25rem 0;
  }

  &.liked {
    background: #FEFEFE;
    border: 1px solid #D76535;
    color: #D76535;

    & > i {
      margin: .125rem 0;
    }
  }

  &:active {
    color: #FEFEFE;
    background: #D76535;
  }
}
</style>
