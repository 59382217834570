import MainHeader from '@/components/shared/MainHeader.vue';
import MainAsideMenu from '@/components/shared/MainAsideMenu.vue';
import NavTab from '@/components/shared/NavTab.vue';
import NavBtnTab from '@/components/shared/controlers/NavBtnTab.vue';
import BottomNav from '@/components/shared/BottomNav.vue';
import UpCard from '@/components/shared/upCard/index.vue';
import SpeakerCard from '@/components/shared/upCard/SpeakerCard.vue';
import ParticipantCard from '@/components/shared/upCard/ParticipantCard.vue';
import PartnerCard from '@/components/shared/upCard/PartnerCard.vue';
import SectionCard from '@/components/shared/upCard/SectionCard.vue';
import LectureItem from '@/components/shared/upCard/SectionCard/LectureItem.vue';
import ProgramCard from '@/components/shared/upCard/ProgramCard.vue';
import NotificationCard from '@/components/shared/upCard/NotificationCard.vue';
import ProfileTop from '@/components/shared/ProfileTop.vue';
import PopupCard from '@/components/shared/popup.vue';
import DropList from '@/components/shared/controlers/droplist.vue';
import upInput from '@/components/shared/controlers/upInput.vue';
import upInputFilter from '@/components/shared/controlers/upInputFilter.vue';
import PreLoader from '@/components/shared/preloader.vue';
import upButton from '@/components/shared/controlers/upButton_simple.vue';

export default [
  MainHeader,
  MainAsideMenu,
  NavTab,
  NavBtnTab,
  BottomNav,
  UpCard,
  SpeakerCard,
  ParticipantCard,
  PartnerCard,
  SectionCard,
  LectureItem,
  ProgramCard,
  NotificationCard,
  ProfileTop,
  PopupCard,
  DropList,
  upInput,
  upInputFilter,
  PreLoader,
  upButton,
];
