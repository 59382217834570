import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getParticipantList(page = null, search = null) {
      this.$store.commit('setProgressVal', 1);
      const urlPage = page ? `/${page}` : '';
      const urlSearch = search ? `?search=${search}` : '';
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/participant/page${urlPage}${urlSearch}`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => {
          this.$store.commit('setProgressVal', 100);
          return data;
        })
        .catch((error) => {
          this.$store.commit('setProgressVal', 100);
          return error;
        });
    },
    getParticipantPersone(id) {
      this.$store.commit('setProgressVal', 1);
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/participant/${id}`,
        onDownloadProgress: (progressEvent) => {
          this.$store.commit('setProgressVal', Math.round(progressEvent.progress * 100));
        },
      })
        .then((data) => data.results)
        .catch((error) => {
          this.$store.commit('setProgressVal', 100);
          return error;
        });
    },
  },
};
